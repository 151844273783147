/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;600;700&display=swap');

:root {
  --background-color: #f8f9fa;
  --gradient-start: #2d588b; /* Deep purple */
  --gradient-end: #427b5b; /* Sea green */
  --primary-gradient: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  --primary-color: #344b76; /* Keeping the purple as primary for non-gradient uses */
  --primary-light: #2b6076;
  --secondary-color: #406a53; /* Medium sea green, complementing the gradient */
  --text-color: #333333;
  --text-color-light: #ffffff;
  --button-background: var(--primary-gradient);
  --button-text: #ffffff;
  --hover-background: #344d88;
  --card-background: #ffffff;
  --card-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

body {
  background-color: var(--background-color);
  font-family: 'Cormorant Garamond', Georgia, serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 600;
}

body, h1, h2, h3, h4, h5, h6, p, a, li, span, button, input, select, textarea {
  font-family: 'Cormorant Garamond', Georgia, serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

p, li, span {
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
}

a {
  font-weight: 700;
}

header {
  margin-top: 0;
  padding-top: 0;
  background: #ffffff;
  color: var(--text-color-light);
}

.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  padding: 0 5%;
  max-width: 1400px;
  margin: 0 auto;
}

.hero {
  background: var(--primary-gradient);
  padding: 0 5% 20px;
  display: flex;
  align-items: center;
  min-height: 70vh;
  color: var(--text-color-light);
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
}

.hero-text {
  width: 75%;
}

.title {
  font-size: 4rem;
  color: var(--text-color-light);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 1.8rem;
  color: var(--text-color-light);
  max-width: 600px;
  margin-bottom: 25px;
  font-weight: 600;
}

.section-title {
  font-size: 3rem;
  color: #235b7e;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  font-weight: 700;
}
.section-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: var(--primary-gradient);
  margin: 15px auto 0;
}

.cta-container {
  width: 25%;
  display: flex;
  justify-content: center;
}

.button, .cta-button {
  display: inline-block;
  background: var(--button-background);
  color: var(--text-color-light);
  padding: 15px 30px;
  font-size: 1.3rem;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 700;
  transition: var(--transition);
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 1px;
}
.button:hover, .cta-button:hover {
  opacity: 0.9;
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
}

.features-section {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: var(--card-background);
  border-radius: 20px;
  padding: 40px;
  box-shadow: var(--card-shadow);
  transition: var(--transition);
  border-left: 4px solid;
  border-image: var(--primary-gradient);
  border-image-slice: 1;
}

.feature-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.feature-item.reverse {
  flex-direction: row-reverse;
}

.feature-image {
  width: 50%;
  max-width: 600px;
  height: auto;
  border-radius: 15px;
  box-shadow: var(--card-shadow);
  transition: var(--transition);
}

.feature-image:hover {
  transform: scale(1.05);
}

.feature-text h3 {
  font-size: 2.3rem;
  color: #235b7e;
  margin-bottom: 20px;
  font-weight: 700;
}

.feature-text p {
  font-size: 1.4rem;
  line-height: 1.6;
}

.job-search-strategy-section {
  background-color: var(--card-background);
  border-radius: 20px;
  padding: 40px;
  box-shadow: var(--card-shadow);
}

.job-search-strategy-section ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 25px;
}

.job-search-strategy-section li {
  margin-bottom: 12px;
  position: relative;
  padding-left: 30px;
  font-size: 1.4rem;
}

.job-search-strategy-section li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--secondary-color);
  font-size: 1.3rem;
  font-weight: bold;
}

.packages-section {
  text-align: center;
  padding: 50px 20px;
}

.packages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.package {
  background-color: var(--card-background);
  border-radius: 15px;
  padding: 25px;
  text-align: left;
  box-shadow: var(--card-shadow);
  transition: var(--transition);
  position: relative;
}

.package.featured {
  border: 3px solid var(--primary-color);
  background-color: #f9f4ff;
}

.package:hover {
  transform: scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.package h3 {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 15px;
  font-weight: 700;
  text-align: center;
}

.package ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
}

.package li {
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.package li::before {
  content: '✓';
  margin-right: 8px;
  color: var(--secondary-color);
  font-weight: bold;
}

.price {
  font-size: 1.8rem;
  color: var(--primary-color);
  font-weight: bold;
  margin: 15px 0;
  text-align: center;
}

.faq-section {
  margin-bottom: 60px;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-item {
  background-color: var(--card-background);
  border-radius: 20px;
  padding: 20px;
  box-shadow: var(--card-shadow);
  cursor: pointer;
  transition: var(--transition);
}

.faq-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.faq-item h3 {
  color: #235b7e;
  margin-bottom: 12px;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.faq-item h3::after {
  content: '+';
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.faq-item.open h3::after {
  transform: rotate(45deg);
}

.faq-item p {
  display: none;
  margin-top: 12px;
  font-size: 1.3rem;
  line-height: 1.5;
}

.faq-item.open p {
  display: block;
}

.main-content > section:not(:last-child) {
  margin-bottom: 60px;
}

/* Resources Page Styles */

.resources-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.resources-content h1 {
  font-size: 3rem;
  color: #235b7e;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.resources-content > p {
  font-size: 1.4rem;
  color: #555;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

.resource-filters {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.resource-filters button {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 12px 24px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  font-weight: 600;
}

.resource-filters button:hover,
.resource-filters button.active {
  background-color: #235b7e;
  color: #fff;
}

.resource-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 50px;
}

.resource-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.resource-card:hover {
  transform: translateY(-5px);
}

.resource-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.resource-content {
  padding: 20px;
}

.resource-content h3 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: #235b7e;
  font-weight: 700;
}

.resource-content p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 15px;
  font-weight: 600;
}

.resource-link {
  display: inline-block;
  background-color: #235b7e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.resource-link:hover {
  background-color: #562f73;
}

.featured-resource {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.featured-resource h2 {
  font-size: 2.4rem;
  color: #235b7e;
  margin-bottom: 20px;
  font-weight: 700;
}

.featured-content {
  display: flex;
  align-items: center;
}

.featured-content img {
  width: 300px;
  height: auto;
  margin-right: 30px;
  border-radius: 8px;
}

.featured-text h3 {
  font-size: 1.8rem;
  color: #235b7e;
  margin-bottom: 15px;
  font-weight: 700;
}

.featured-text p {
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 20px;
  font-weight: 600;
}

.download-button {
  display: inline-block;
  background-color: #235b7e;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.download-button:hover {
  background-color: #562f73;
}

.resource-newsletter {
  background-color: #235b7e;
  color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
}

.resource-newsletter h2 {
  font-size: 2.4rem;
  margin-bottom: 15px;
  font-weight: 700;
  color: #ffffff;
}

.resource-newsletter p {
  font-size: 1.3rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #ffffff;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
}

.newsletter-form input {
  flex-grow: 1;
  padding: 12px;
  border: none;
  border-radius: 4px 0 0 4px;
  font-size: 1.1rem;
}

.newsletter-form button {
  background-color: #0b3854;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.1rem;
  font-weight: 600;
}

.newsletter-form button:hover {
  background-color: #4a2862;
}

@media (max-width: 768px) {
  .resources-content h1 {
    font-size: 2.5rem;
  }

  .resources-content > p {
    font-size: 1.2rem;
  }

  .resource-filters button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .featured-content {
    flex-direction: column;
  }

  .featured-content img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .featured-resource h2 {
    font-size: 2rem;
  }

  .featured-text h3 {
    font-size: 1.5rem;
  }

  .featured-text p {
    font-size: 1.1rem;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form input,
  .newsletter-form button {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .resource-newsletter h2 {
    font-size: 2rem;
  }

  .resource-newsletter p {
    font-size: 1.1rem;
  }
}
/* Mobile Responsiveness */

/* General adjustments for small screens (up to 768px) */
@media (max-width: 768px) {
  .main-content {
    padding: 0 15px;
  }

  .hero {
    flex-direction: column;
    text-align: center;
    padding: 20px 15px;
  }

  .hero-text, .cta-container {
    width: 100%;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .button, .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .features-section {
    gap: 30px;
  }

  .feature-item {
    flex-direction: column;
    padding: 20px;
  }

  .feature-item.reverse {
    flex-direction: column;
  }

  .feature-image {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .feature-text h3 {
    font-size: 1.8rem;
  }

  .feature-text p {
    font-size: 1.2rem;
  }

  .packages {
    grid-template-columns: 1fr;
  }

  .package h3 {
    font-size: 1.6rem;
  }

  .price {
    font-size: 1.5rem;
  }

  .faq-item h3 {
    font-size: 1.3rem;
  }

  .faq-item p {
    font-size: 1.1rem;
  }

  .resources-content h1 {
    font-size: 2rem;
  }

  .resources-content > p {
    font-size: 1.2rem;
  }

  .resource-filters button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .resource-grid {
    grid-template-columns: 1fr;
  }

  .featured-content {
    flex-direction: column;
  }

  .featured-content img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .featured-resource h2 {
    font-size: 1.8rem;
  }

  .featured-text h3 {
    font-size: 1.5rem;
  }

  .featured-text p {
    font-size: 1.1rem;
  }

  .newsletter-form {
    flex-direction: column;
    gap: 10px;
  }

  .newsletter-form input,
  .newsletter-form button {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .resource-newsletter h2 {
    font-size: 1.8rem;
  }

  .resource-newsletter p {
    font-size: 1.1rem;
  }
}

/* Extra Small Screens (up to 480px) */
@media (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .button, .cta-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .feature-text h3 {
    font-size: 1.6rem;
  }

  .feature-text p {
    font-size: 1rem;
  }

  .package h3 {
    font-size: 1.4rem;
  }

  .price {
    font-size: 1.3rem;
  }

  .faq-item h3 {
    font-size: 1.2rem;
  }

  .faq-item p {
    font-size: 1rem;
  }

  .resources-content h1 {
    font-size: 1.8rem;
  }

  .resource-filters button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .resource-newsletter h2 {
    font-size: 1.6rem;
  }

  .resource-newsletter p {
    font-size: 1rem;
  }
}

